<template>
  <div>
    <div class="row mt-2">
      <div class="col-12 table-responsive">
        <table class="table table-bordered">
          <tr>
            <th colspan="2">INDICE DE RIESGO REPRODUCTIVO</th>
          </tr>
          <tr>
            <th>Aspecto a evaluar</th>
            <th></th>
          </tr>
          <tr v-for="item in $v.instrumentos.$each.$iter" :key="item.$model.id_opcion">
            <th>{{item.$model.pregunta}}</th>
            <th>
              <select class="form-control" v-model="item.$model.id_opcion" :class="{ 'is-invalid': item.$error }">
                <option value="">--seleccione una opcion--</option>
                <option :value="opc.id" v-for="opc in item.$model.opciones" :key="opc.id">
                  {{opc.respuesta}}
                  ( = {{opc.puntuacion }} )
                </option>
              </select>
            </th>
          </tr>
        </table>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        Puntuación Total : {{puntuacion_total}}
      </div>
    </div>
  </div>

</template>
<script>
import instrumentoService from "../../../../services/instrumentoService";
import historiaInstrumentoService from "../../../../services/historiaInstrumentoService";
import {isEmpty} from "lodash";
import Toast from '../../../../components/common/utilities/toast';
import {required} from "vuelidate/lib/validators";
export default {
  props: ['idHistoria','finalizada'],
  data() {
    return {
      instrumentos:[],
      id_instrumento : '',
      codigo: 'riesgo_resproductivo',
      hasStore : false,
      id_instrumento_historia : '',
    }
  },
  validations(){
    return {
      instrumentos : {
        $each : {
          id_opcion : {required}
        }
      }
    }
  },
  methods: {
    async cargarInstrumentos(){
      const response = await instrumentoService.showByCodigo(this.codigo);
      this.id_instrumento = response.data.id;
      this.instrumentos= response.data.preguntas.map(pregunta => {
        return {
          ...pregunta,
          id_opcion : '',
        }
      });
    },
    async save(){
      try {
        
        this.$v.instrumentos.$touch();

        if(this.$v.instrumentos.$invalid){
          return false;
        }

        let obj = {
          ...(this.hasStore ? {id_instrumento_historia : this.id_instrumento_historia} : {}),
          id_historia : this.idHistoria,
          id_instrumento : this.id_instrumento,
          puntuacion_total : this.puntuacion_total,
          respuestas : this.instrumentos.map(x => {
            let puntaje = (x.opciones.find(y=> y.id === parseInt(x.id_opcion)))?.puntuacion || 0;
            return {
              ...x,
              puntaje : puntaje,
            };
          })
        }

        this.LoaderSpinnerShow();

        if(this.hasStore) {
          await historiaInstrumentoService.update(obj);
          const response_historia = await historiaInstrumentoService.showByHcI(this.idHistoria,this.id_instrumento);
          this.id_instrumento_historia=response_historia.data.id;
          this.hasStore=true;
        }else {
          await historiaInstrumentoService.store(obj);
          const response_historia = await historiaInstrumentoService.showByHcI(this.idHistoria,this.id_instrumento);
          this.id_instrumento_historia=response_historia.data.id;
          this.hasStore=true;
        }


        this.LoaderSpinnerHide();

         Toast.fire({
          icon: 'success',
          title: 'Datos guardados con Exito'
        });

      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
         Toast.fire({
          icon: 'error',
          title: 'Ha ocurrido un error al procesar la solicitud'
        });
      }
    }
  },
  async created(){

    await this.cargarInstrumentos();

    if(this.finalizada==='N'){
      const response_historia = await historiaInstrumentoService.showByHcI(this.idHistoria,this.id_instrumento);
      if(!isEmpty(response_historia.data)){
        this.hasStore = true;
        this.id_instrumento_historia = response_historia.data.id;
        this.instrumentos.forEach((pregunta, i) => {
          let id_opcion = (response_historia.data.detalles.find(x => x.opcion.id_pregunta === pregunta.id))?.id_opcion || '';
          console.log(id_opcion, i);
          this.instrumentos[i].id_opcion = id_opcion;
        });
      }
    }
  },
  computed: {
    puntuacion_total : function(){
      return this.instrumentos.reduce((t,c) => {
        let puntaje = (c.opciones.find(x=> x.id === parseInt(c.id_opcion)))?.puntuacion || 0;
        return t + puntaje;
      }, 0);
    }
  }
}
</script>